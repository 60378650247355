
import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L1.scss';

export default class B11U1L1 extends Component {

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

 
    return (

      // <div class="contenedor"> 
 
<Grid style={{ backgroundColor: '#101010'}}>

  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
    <>
    <h2 className="lesson1title"><br></br>Lesson 1</h2>
    <Slider {...settings}>
    <div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU8ES%2Fa2c3bda0-9ff9-4508-96fe-bd282bcc9535?alt=media&token=57f83b79-c09a-40b0-b64d-729b1c5f9cfc"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU8ES%2Fcb9359e3-a82e-4f46-8a0d-aa20f5ec9fee?alt=media&token=31e08f2f-7881-46ae-80ea-501ad6e4e54a"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU8ES%2Fd9d4bc4f-79a4-4da5-ae4d-d2d50232bef4?alt=media&token=62ae9062-6d2d-4152-89c7-b16465d40e58"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU8ES%2Fbedc03c0-8dca-4689-8e9d-06ec732e14eb?alt=media&token=8803cbdf-2b7c-4ef8-858d-024fbf0a8b18"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU8ES%2F9731dc60-dc12-4841-9c14-b84fa4641bad?alt=media&token=4e39954d-4816-47d5-9448-1898bd6839c5"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU8ES%2F5f9c549d-3a5a-4f1b-8284-6df4d329a0c7?alt=media&token=df43ba63-2585-4c53-87bf-98228f12ca63"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU8ES%2F86b9d4b2-e9bf-4de2-b919-022f88c415b1?alt=media&token=c88502c1-51ec-43b3-9c31-7772d9abf9d5"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU8ES%2F77d31bb5-e168-4cb4-abe1-44648ee31470?alt=media&token=9fc567be-e159-4ab5-ab44-add00ee82c65"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU8ES%2F11b983b7-5bbe-48d2-92ae-3eb5905a2191?alt=media&token=4b80a0b2-b8a4-4556-8a66-8e290c70f949"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU8ES%2F306f4f8c-543b-4425-912f-d7fa9749037d?alt=media&token=e5e364a3-1eb6-40d1-9bc7-fd95d0afa6eb"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU8ES%2F3b42f4f3-1876-4319-b22c-4ffae4917aa6?alt=media&token=425ee8a5-2c68-4281-94ab-9f27a821be3e"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU8ES%2F2057aa22-f958-4f6e-adc8-a2cd04f8f574?alt=media&token=905ab80e-afc1-4211-8639-6d73780843f9"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU8ES%2F1aa1007a-3cbb-41ac-8b93-8831c95a07bf?alt=media&token=d5afe747-d656-431f-b1dc-1800149f4d71"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU8ES%2Fd412a477-bf20-4367-b0e7-e158cdf313b7?alt=media&token=33d695b2-795f-42bd-9cbc-50f710e0def5"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU8ES%2Fda369c57-10dd-49bf-9a5c-4804a306bba9?alt=media&token=83864b6e-000d-4bce-a19f-b6d54e05b009"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU8ES%2F636d09df-2fb1-4bc1-997e-dcc19b98e311?alt=media&token=727f8ff5-f2ba-4ae0-9374-5698ebdaadae"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU8ES%2Ffeb9b942-d86b-4d29-86c3-5c0d9b3b483a?alt=media&token=e9dffdf4-9045-42dd-81e7-9766333d90a7"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU8ES%2F4a1baef9-4e72-41c8-a6a9-e97a55453479?alt=media&token=a94de4fa-618e-43cd-a298-0ba3476edb80"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU8ES%2Fd3c73ac8-f50b-4e6a-98eb-97bc3bc281ee?alt=media&token=313b90d0-4808-43b0-9231-b64f4bb02df2"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU8ES%2Fbe640ac7-4a07-4fca-9ccf-d842fc8c1dfd?alt=media&token=bfaf6787-fdc2-4dae-84ea-3170d1651452"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU8ES%2F92e3d935-5451-4921-a75a-e4feb85fb3b4?alt=media&token=87c9568f-f22a-4296-a27b-abf4d0d445b5"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU8ES%2Ff918f1ea-bd50-4d7d-9485-2126a53adc50?alt=media&token=bc4e2356-0bfc-4068-bba5-d652cd432208"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU8ES%2F87ddd355-7a67-464d-b454-7ec8c69b3658?alt=media&token=225dd729-3bbe-40c7-843f-2c5f1d1fa480"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
          

        </Slider>
      </>
      <div class="center"> 
      <Button href='https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU8ES%2FDIVU8ESMAT.pdf?alt=media&token=14ac4564-3db8-4104-b90a-54f3d8274291' download className="btn-download-uno">Download resources</Button>
      </div>
  </Grid.Column>
  
  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
</Grid>
 
      // </div>
    );
  }
}
